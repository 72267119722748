/* stylelint-disable selector-max-compound-selectors */

/*==========================================================================*\
  Global
\*==========================================================================*/

/* Base
\*==========================================================================*/

*,
*::before,
*::after {
	position: relative;
}

html {
	overflow-x: hidden;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	scroll-behavior: smooth;
}

body {
	overflow-x: hidden;
	font-variation-settings: "wght" 500;

	@apply font-sans;
	@apply text-white;
	@apply bg-gradient-to-br from-purple-200 to-purple-400;
}

h1,
h2,
h3 {

	@apply font-title;
	@apply uppercase;
	@apply font-normal;

	&:not(:last-child) {

		@apply mb-8;
	}
}

h2 {

	@apply text-3xl;

	@screen s {

		@apply text-5xl;
	}
}

h3 {

	@apply text-lg;
	@apply leading-tight;

	@screen s {

		@apply text-2xl;
		@apply leading-tight;
	}

	&:not(:first-child) {

		@apply mt-8;
	}

	&:not(:last-child) {

		@apply mb-4;
	}
}

p {

	&:not(:last-child) {

		@apply mb-6;
	}
}

// Inline tag
strong,
b {
	font-weight: 700;
}

em {
	font-style: italic;
}

a {
	text-decoration: none;
	cursor: pointer;
}

// Image and figure
img {
	height: auto;
}

/* Selection
\*==========================================================================*/

::-moz-selection {
	text-shadow: none;

	@apply text-white;
	@apply bg-black;
}

::selection {
	text-shadow: none;

	@apply text-white;
	@apply bg-black;
}
