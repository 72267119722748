.wysiwyg,
.mce-content-body {

	&.mce-content-body {

	}

	&.js-reveal {

		>*:not([class*="flex"]):not(.bodymovin):not(.no-reveal),
		ul:not(.no-reveal) > li,
		> [class*="flex"]:not(.no-reveal) > div {

			@apply opacity-0;
			@apply transform;
			@apply translate-y-10;
			transition-property: opacity, transform;
			transition-timing-function: ease-in-out, theme("transitionTimingFunction.out-expo");
			transition-duration: 0.8s, 1.5s;
		}

		&.is-visible {

			>*:not([class*="flex"]):not(.bodymovin):not(.no-reveal),
			ul:not(.no-reveal) > li,
			> [class*="flex"]:not(.no-reveal) > div {

				@apply opacity-100;
				@apply translate-y-0;
			}

			>*:not([class*="flex"]):not(.bodymovin):not(.no-reveal),
			ul:not(.no-reveal) > li,
			> [class*="flex"]:not(.no-reveal) {

				@for $i from 0 through 15 {
					&:nth-child(#{$i + 1}):not([class*="delay-"]) {
						transition-delay: #{$i * 0.1s};

						@for $j from 0 through 3 {

							&[class*="flex"] {
								div:nth-child(#{$j + 1}) {
									transition-delay: #{$i * 0.1s + $j * 0.05s};
								}
							}
						}
					}
				}
			}

			@for $k from 0 through 3 {
				&.delay-#{$k * 100} {

					>*:not([class*="flex"]):not(.bodymovin):not(.no-reveal),
					ul:not(.no-reveal) > li,
					> [class*="flex"]:not(.no-reveal) {

						@for $i from 0 through 6 {
							&:nth-child(#{$i + 1}):not([class*="delay-"]) {
								transition-delay: #{$i * 0.1s + $k * 0.1s};

								@for $j from 0 through 3 {

									&[class*="flex"] {
										div:nth-child(#{$j + 1}) {
											transition-delay: #{$i * 0.1s + $j * 0.05s + $k * 0.1s};
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	ul,
	ol {

		&:not(:last-child) {

			@apply mb-6;
		}
	}

	ul {

		li {

			&:not(:last-child) {

				@apply mb-2;

				@screen s {

					@apply mb-2;
				}
			}

			&::before {
				content: "";

				@apply w-3 h-3;
				@apply inline-block;
				@apply bg-no-repeat bg-center;
				@apply opacity-90;
				@apply mr-2;
				background-size: 12px auto;
				background-image: url(#{$svg-dir}eclr.svg);
			}
		}
	}
}
