.btn {

	@apply inline-flex items-center justify-center;
	@apply cursor-pointer;
	@apply h-9 px-3;
	@apply bg-white;
	@apply rounded-full;
	@apply uppercase;
	@apply text-black;
	@apply text-sm;
	@apply transition-all duration-300;

	&:hover {

		@apply -translate-y-1;
	}

	svg {

		@apply ml-2;

		&:last-child {

			@apply -mr-1;
		}
	}
}
